<template>
  <Tooltip v-bind="props" :isOpen="isVisible" ref="elem">
    <span :style="style" class="sticky-tooltip" />
  </Tooltip>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from "vue";

import { eventHandler, isHtmlElem, throttle } from "@horizon56/utils";

import Tooltip, { TooltipProps } from "@/components/tooltips/tool-tip.vue";

const props = defineProps<TooltipProps & { throttleWait?: number }>();

const timer = ref(-1);
const style = ref("");
const isVisible = ref(false);
const elem = ref<InstanceType<typeof Tooltip>>();

const bindTo = () => elem?.value?.$el?.parentElement;

const updatePlacement = throttle(
  (e: MouseEvent) => {
    const target = bindTo();
    if (!isHtmlElem(target)) {
      return;
    }
    const { left, top } = target.getBoundingClientRect();

    style.value = `left:${e.clientX - left}px;top:${e.clientY - top}px;`;
  },
  props.throttleWait ?? 10,
  { leading: true },
);

const movementEvents = eventHandler(bindTo, [["mousemove", updatePlacement]]);

const initialEvents = eventHandler(bindTo, [
  [
    "mouseenter",
    (e: MouseEvent) => {
      timer.value = window.setTimeout(() => {
        isVisible.value = true;
      }, 200);
      updatePlacement(e);
      movementEvents.add();
    },
  ],
  [
    "mouseleave",
    () => {
      window.clearTimeout(timer.value);
      isVisible.value = false;
      style.value = "";
      updatePlacement.cancel();
      movementEvents.remove();
    },
  ],
]);

onMounted(initialEvents.add);

onBeforeUnmount(() => {
  initialEvents.remove();
  movementEvents.remove();
});
</script>

<style lang="scss" scoped>
.sticky-tooltip {
  width: 2px;
  height: 2px;
  border-radius: 50%;
  opacity: 0;
  display: block;
  position: absolute;
  pointer-events: none;
  left: -1px;
  top: -1px;
  transform: translate(-50%, -50%);
}
</style>
